import {
	Splide
} from '@splidejs/splide';
import AOS from 'aos';
import Headroom from 'headroom.js';


(function ($) {

	// grab an element
	var navElement = document.querySelector("nav");
	// construct an instance of Headroom, passing the element
	var headroomOptions = {
		"offset": 100,
		"tolerance": 5,
		"classes": {
		  "initial": "animated",
		  "pinned": "slideDown",
		  "unpinned": "slideUp"
		}

	};
	var headroom  = new Headroom(navElement, headroomOptions);
	// initialise
	headroom.init();

	$(document).ready(function () {
		AOS.init();
	});

    $(document).ready(function () {
		var elms = document.getElementsByClassName('splide-services');
		for (var i = 0; i < elms.length; i++) {
			new Splide(elms[i], {
				type: 'loop',
				gap: '4rem',
				arrows: false,
				perMove: 1,
				speed: 200,
				pagination: true,
				interval: 0,
				rewind: 'true',
				focus: 1,
				arrows: false,
				padding: {
					left: 150,
					right: 150
				},
				perPage: 3,
				breakpoints: {
					1400: {
						perPage: 2,
					},
					1000: {
						perPage: 1,
						padding: {
							left: 80,
							right: 80
						},
					},
				}
			}).mount();
		}
	});
	
	$(document).ready(function () {
		var elms = document.getElementsByClassName('splide-generic');
		for (var i = 0; i < elms.length; i++) {
			new Splide(elms[i], {
				type: 'loop',
				arrows: false,
				perPage: 3,

				perMove: 1,
				speed: 200,
				gap: '3rem',
				pagination: true,
				rewind: 'true',
				arrows: false,
				padding: {
					left: 150,
					right: 150
				},
				breakpoints: {

					1900: {
						perPage: 2,

					},

					1200: {
						gap: '1rem',
						perPage: 1,

						padding: {
							left: 80,
							right: 80
						},
					},
				}
			}).mount();
		}
	});

	$(document).ready(function () {
		var elms = document.getElementsByClassName('splide-testimonials');
		for (var i = 0; i < elms.length; i++) {
			new Splide(elms[i], {
				type: 'fade',
	
				perMove: 1,
				speed: 500,
				pagination: false,
				interval: 0,
				rewind: 'true',
				focus: 1,
				arrows: true,
				perPage: 1,

			}).mount();
		}
	});


$(document).ready(function() {
  const imageBanner = $('.image-banner');
  if (imageBanner.length > 0) {
    const stickyBar = $('.sticky-bar');
	const cookieBanner = $('.cmplz-cookiebanner');
    const imageBannerHeight = imageBanner.outerHeight() + 300;
    let hasScrolled = false;
    const tolerance = 10;

    $(window).scroll(function() {
      if ($(this).scrollTop() >= imageBannerHeight - tolerance && !hasScrolled) {
        stickyBar.addClass('sticky');
        setTimeout(function() {
          stickyBar.addClass('active');
		  cookieBanner.addClass('cookie-bottom');
        }, 200); // Adjust the delay time as needed
        hasScrolled = true;
      } else if ($(this).scrollTop() < imageBannerHeight - tolerance && hasScrolled) {
        stickyBar.removeClass('active');
		cookieBanner.removeClass('cookie-bottom');
        setTimeout(function() {
          stickyBar.removeClass('sticky');
        }, 200); // Adjust the delay time as needed
        hasScrolled = false;
      }
    });
  }
});

	  
	  

	//   $(document).ready(function () {
	// 	// Get the navbar element
	// 	const navbar = $("#main-nav");
	// 	let prevScrollPos = $(window).scrollTop();
	// 	let scrolledDown = false;

	// 	// Listen for the scroll event
	// 	$(window).scroll(function () {
	// 		const currentScrollPos = $(window).scrollTop();

	// 		if (currentScrollPos > 500) {
	// 			scrolledDown = true;
	// 		}

	// 		if (scrolledDown) {
	// 			if (prevScrollPos > currentScrollPos) {
	// 				// User is scrolling up
	// 				if (currentScrollPos <= 700) {
	// 					navbar.removeClass("fixed-top");
	// 					navbar.removeClass("navbar-hidden");
	// 				} else {
	// 					navbar.addClass("fixed-top");
	// 					navbar.removeClass("navbar-hidden");
	// 				}
	// 			} else {
	// 				// User is scrolling down or not scrolling
	// 				navbar.addClass("navbar-hidden");
	// 			}
	// 		}

	// 		prevScrollPos = currentScrollPos;
	// 	});
	// });


	  
    
})(jQuery);
